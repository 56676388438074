var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mb-5 mt-3 no-container-padding section-container" },
    [
      _vm._t("default"),
      _c(
        "b-container",
        [
          _c(
            "b-card-group",
            { attrs: { deck: true } },
            _vm._l(_vm.items, function (item) {
              return _c(
                "b-card",
                {
                  key: item,
                  attrs: { borderVariant: "light", noBody: "", imgTop: "" },
                },
                [
                  _c("b-skeleton-img", { attrs: { cardImg: "top" } }),
                  _c(
                    "b-card-body",
                    { staticClass: "d-flex flex-column" },
                    [
                      _c("b-skeleton", { attrs: { width: "85%" } }),
                      _c("b-skeleton", { attrs: { width: "55%" } }),
                      _c("b-skeleton", { attrs: { width: "35%" } }),
                      _c("b-skeleton", {
                        staticClass: "mt-5",
                        attrs: { type: "button" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }