var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _vm.hasScrolled
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-hover",
                  rawName: "v-b-hover",
                  value: _vm.handleHover,
                  expression: "handleHover",
                },
              ],
              staticClass: "back-to-top rounded-circle",
              class: {
                "shadow-sm": !_vm.isHovered,
                shadow: _vm.isHovered,
              },
              attrs: { variant: "primary" },
              on: { click: _vm.goToTop },
            },
            [_c("ChevronUpIcon")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }