var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "icon", staticClass: "icon" }, [
      _vm.answer === "right"
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip:hover.right",
                  arg: "hover",
                  modifiers: { right: true },
                },
              ],
              staticClass: "text-success",
              attrs: { title: _vm.tooltipText },
            },
            [_c("b-icon-check-circle-fill")],
            1
          )
        : _vm.answer === "wrong"
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip:hover.right",
                  arg: "hover",
                  modifiers: { right: true },
                },
              ],
              attrs: { title: _vm.tooltipText },
            },
            [_c("b-icon-x")],
            1
          )
        : _vm.answer === "hint"
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip:hover.right",
                  arg: "hover",
                  modifiers: { right: true },
                },
              ],
              attrs: { title: _vm.tooltipText },
            },
            [_c("b-icon-info-circle-fill")],
            1
          )
        : _vm.answer === "rectified"
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip:hover.right",
                  arg: "hover",
                  modifiers: { right: true },
                },
              ],
              attrs: { title: _vm.tooltipText },
            },
            [_c("b-icon-record-fill")],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }