var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        headerCloseVariant: "light",
        size: "xl",
        centered: "",
      },
      on: { shown: _vm.onShown, hidden: _vm.onHidden },
    },
    [
      _c(
        "div",
        {
          ref: "mainDiv",
          staticClass: "bg-white text-dark wrapper",
          attrs: { tabindex: "0" },
          on: { keyup: _vm.onKeyUp },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "pt-3", attrs: { md: "3", sm: "12" } },
                [
                  _c(
                    "b-list-group",
                    {
                      directives: [
                        {
                          name: "b-scrollspy",
                          rawName: "v-b-scrollspy:sections-column",
                          arg: "sections-column",
                        },
                      ],
                    },
                    _vm._l(_vm.menuItems, function (item) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: item.id,
                          ref: "ref-" + item.id,
                          refInFor: true,
                          staticClass: "rounded-0",
                          attrs: {
                            id: item.id,
                            active: item.id === _vm.activeItemId,
                            href: item.href,
                          },
                          on: { click: _vm.setActiveItem },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.label) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "sections-column",
                  attrs: { id: "sections-column", md: "9", sm: "12" },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "pt-3 text-primary",
                          attrs: { id: "about-section" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tr("aboutHeading")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c("p", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tr("aboutBody")) +
                            "\n          "
                        ),
                      ]),
                      _c("hr"),
                      _vm.buildInfo
                        ? [
                            _c("h5", [
                              _vm._v(
                                _vm._s(_vm.$tr("releaseInformationHeading"))
                              ),
                            ]),
                            _c(
                              "b-list-group",
                              { staticClass: "bg-light p-3 rounded-lg" },
                              [
                                _c(
                                  "b-list-group-item",
                                  { staticClass: "bg-light rounded-0" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$tr("releaseKey")) +
                                        " " +
                                        _vm._s(_vm.buildInfo.version_name) +
                                        " " +
                                        _vm._s(_vm.buildInfo.last_release) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-list-group-item",
                                  { staticClass: "bg-light rounded-0" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$tr("dateKey")) +
                                        " " +
                                        _vm._s(_vm.translatedBuildDate) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-list-group-item",
                                  { staticClass: "bg-light rounded-0" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$tr("commitKey")) +
                                        " " +
                                        _vm._s(_vm.buildInfo.commit) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-list-group-item",
                                  { staticClass: "bg-light rounded-0" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$tr("kolibriKey")) +
                                        " " +
                                        _vm._s(_vm.kolibriVersion) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("hr"),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("div", [
                    _c(
                      "h3",
                      {
                        staticClass: "pt-3 text-primary",
                        attrs: { id: "support-section" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tr("supportHeading")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.$tr("supportBody")) },
                    }),
                    _c("hr"),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "pt-3 text-primary",
                          attrs: { id: "privacy-policy-section" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tr("privacyPolicyHeader")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c("EkPrivacyPolicyText"),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "h3",
                      {
                        staticClass: "pt-3 text-primary",
                        attrs: { id: "credits-section" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tr("imagesAndIconsHeading")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _c("h5", [
                      _vm._v(_vm._s(_vm.$tr("creditsFinancialLiteracy"))),
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "savings",
                              logoAuthor: "abderraouf omara",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Sharon McCutcheon",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Sharon McCutcheon",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsGardening")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "Beetroot",
                              logoAuthor: "dDara",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Julia Kadel",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Julia Kadel",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsHealthyMind")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "well being",
                              logoAuthor: "Karen Santiago",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Alejandro Piñero Amerio",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Clyde RS",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsReadAlong")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "Reading",
                              logoAuthor: "dDara",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Annie Spratt",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Roman Trifonov",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsMusic")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "Guitar",
                              logoAuthor: "dDara",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Sigmund",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Sigmund",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsHealthyBody")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "yoga",
                              logoAuthor: "Eucalyp",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "madison lavern",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Conscious Design",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsDance")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "dance",
                              logoAuthor: "ArmOkay",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Drew Graham",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Ahmad Odeh",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsSteam")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "Science",
                              logoAuthor: "Turkkub",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "David Clode",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Joel Filipe",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsSports")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "Basketball",
                              logoAuthor: "Eucalypt",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Steven Lelham",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Caroline Justine",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsArtsAndCrafts")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "Scissor",
                              logoAuthor: "Muhammad Auns",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Siora Photography",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Munro Studio",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsCareers")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "Path",
                              logoAuthor: "Adrien Coquet",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Estee Janssens",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Shane",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsCooking")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "Cooking Tool",
                              logoAuthor: "Made by Made",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "LUM3N",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "LUM3N",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsGames")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "Games",
                              logoAuthor: "dDara",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Block of Netherite",
                              thumbnailLocation: "youtube.com",
                              backgroundAuthor: "Ty Feague",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [
                      _vm._v(_vm._s(_vm.$tr("creditsMediaThumbnails"))),
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$tr("iconCreditsEntry")) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsFashion")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$tr("fashionCreditsEntry")) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsJewelryMaking")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "earrings",
                              logoAuthor: "Eucalyp",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "FLY:D",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "FLY:D",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [
                      _vm._v(_vm._s(_vm.$tr("creditsGenderIdentity"))),
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$tr("genderIdentityCreditsEntry")) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsBeauty")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "Beauty",
                              logoAuthor: "ferdizzimo",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Siora Photography",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Valeriia Kogan",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [
                      _vm._v(_vm._s(_vm.$tr("creditsVirtualFieldTrips"))),
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "Map",
                              logoAuthor: "Eucalyp",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Juan Ordonez",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Jad Limcaco",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsChapterBooks")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$tr("chapterBooksCreditsEntry")) +
                          "\n          "
                      ),
                    ]),
                    _c("h5", [_vm._v(_vm._s(_vm.$tr("creditsStaffPlaylist")))]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("genericCreditsEntry", {
                              logoName: "feedback",
                              logoAuthor: "Andi",
                              logoLocation: "NounProject.com",
                              thumbnailAuthor: "Sharon McCutcheon",
                              thumbnailLocation: "unsplash.com",
                              backgroundAuthor: "Sharon McCutcheon",
                              backgroundLocation: "unsplash.com",
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }