import { render, staticRenderFns } from "./LessonMasteryBar.vue?vue&type=template&id=5648cec8&scoped=true&"
import script from "./LessonMasteryBar.vue?vue&type=script&lang=js&"
export * from "./LessonMasteryBar.vue?vue&type=script&lang=js&"
import style0 from "./LessonMasteryBar.vue?vue&type=style&index=0&id=5648cec8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/kolibri-tools/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5648cec8",
  null
  
)

export default component.exports