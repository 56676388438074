var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      directives: [
        {
          name: "b-hover",
          rawName: "v-b-hover",
          value: _vm.handleHover,
          expression: "handleHover",
        },
      ],
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      [
        _c("div", { staticClass: "img", style: _vm.backgroundStyle }),
        _c("b-card-text", [
          _c("div", { staticClass: "card-content" }, [
            _c(
              "div",
              {
                staticClass: "d-lg-block d-none dh3 mb-1 title",
                class: { "text-primary": _vm.isHovered },
              },
              [
                _c("EkCarouselCardTitle", {
                  attrs: {
                    node: _vm.node,
                    showDescription: !_vm.showChannelIcon,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "d-lg-none dh4 mb-1 title",
                class: { "text-primary": _vm.isHovered },
              },
              [
                _c("EkCarouselCardTitle", {
                  attrs: {
                    tag: "h4",
                    node: _vm.node,
                    lines: 3,
                    showDescription: !_vm.showChannelIcon,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "align-items-center d-flex justify-content-between",
              },
              [
                _vm.showChannelIcon
                  ? _c(
                      "div",
                      { staticClass: "align-items-center d-flex" },
                      [
                        _c("EkChannelLogo", {
                          staticClass: "mr-2",
                          attrs: { channel: _vm.node.channel, size: "sm" },
                        }),
                        _c(
                          "span",
                          {
                            staticClass:
                              "d-md-block d-none pr-2 text-muted text-truncate",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.node.channel.title) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("EkPlayButton", {
                  staticClass: "ml-auto",
                  attrs: { kind: _vm.node.kind, enabled: _vm.node.available },
                  on: {
                    click: function ($event) {
                      return _vm.goToContent()
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }