var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex main-container min-vh-100 text-center" },
    [
      _c(
        "b-container",
        {
          staticClass:
            "d-flex flex-column flex-grow-1 no-container-padding welcome-container",
        },
        [
          _c(
            "b-container",
            {
              staticClass:
                "d-flex flex-column flex-grow-1 justify-content-center no-container-padding",
            },
            [
              _vm._t("header", function () {
                return [
                  _vm._t("header-top"),
                  _vm.title
                    ? _c(
                        "h1",
                        { staticClass: "mb-3", class: _vm.titleVariant },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.title) + "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
              _vm.hasBody
                ? _c(
                    "b-row",
                    { staticClass: "justify-content-center" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "8" } },
                        [_vm._t("body")],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._t("extra-body"),
            ],
            2
          ),
          _vm.hasFooter
            ? _c(
                "b-container",
                {
                  staticClass: "no-container-padding",
                  attrs: { id: "footer" },
                },
                [_vm._t("footer")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }