var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex main-container min-vh-100 text-center" },
    [
      _c(
        "b-container",
        {
          staticClass:
            "d-flex flex-column flex-grow-1 no-container-padding welcome-container",
        },
        [
          _c(
            "b-container",
            {
              staticClass:
                "d-flex flex-column flex-grow-1 justify-content-center no-container-padding",
            },
            [
              _c("h1", { staticClass: "mb-3" }, [
                _vm._v("\n        " + _vm._s(_vm.titleLabel) + "\n      "),
              ]),
              _c("div", {
                staticClass: "pack-background",
                style: { backgroundImage: _vm.packBackgroundUrl },
              }),
              _c(
                "b-row",
                { staticClass: "justify-content-center mt-4" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", sm: "8" } },
                    [
                      _c("b-progress", {
                        attrs: {
                          max: 1,
                          value: _vm.displayProgress,
                          variant: "endless-orange",
                          animated: "",
                        },
                      }),
                      _c("p", { staticClass: "mt-1" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s((_vm.displayProgress * 100).toFixed()) +
                            "%\n          "
                        ),
                      ]),
                      _vm.gotDownloadError
                        ? [
                            _c("p", [_vm._v(_vm._s(_vm.errorMessage))]),
                            _c(
                              "b-button",
                              {
                                attrs: { pill: "", variant: "primary" },
                                on: { click: _vm.onRetry },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$tr("retryLabel")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        : _vm.isCompleted
                        ? [
                            _c(
                              "b-button",
                              {
                                attrs: { pill: "", variant: "primary" },
                                on: { click: _vm.onConfirm },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$tr("confirmLabel")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }