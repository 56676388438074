var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isIframe
    ? _c(
        "div",
        { staticClass: "dev mx-1 text-right" },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal.buildinfo",
                  modifiers: { buildinfo: true },
                },
              ],
              staticClass: "badge badge-warning",
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm.info
            ? _c(
                "b-modal",
                {
                  ref: "dev-modal",
                  attrs: {
                    id: "buildinfo",
                    title: _vm.$tr("aboutThisBuild"),
                    okOnly: "",
                    headerCloseVariant: "light",
                  },
                },
                [
                  _c("h6", { staticClass: "my-3" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$tr("releaseInformation")) +
                        "\n    "
                    ),
                  ]),
                  _c(
                    "b-list-group",
                    { staticClass: "text-dark" },
                    [
                      _c("b-list-group-item", [
                        _vm._v(
                          _vm._s(_vm.$tr("commitKey")) +
                            " " +
                            _vm._s(_vm.info.commit)
                        ),
                      ]),
                      _c("b-list-group-item", [
                        _vm._v(
                          _vm._s(_vm.$tr("dateKey")) +
                            " " +
                            _vm._s(_vm.info.date)
                        ),
                      ]),
                      _c("b-list-group-item", [
                        _vm._v(
                          _vm._s(_vm.$tr("lastReleaseKey")) +
                            " " +
                            _vm._s(_vm.info.last_release)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("h6", { staticClass: "my-3" }, [
                    _vm._v("\n      Log of changes\n    "),
                  ]),
                  _c(
                    "b-list-group",
                    { staticClass: "text-dark" },
                    _vm._l(_vm.info.log, function (commit) {
                      return _c("b-list-group-item", { key: commit.commit }, [
                        _vm._v("\n        " + _vm._s(commit.subject) + " "),
                        _c("br"),
                        _c("i", [_vm._v(_vm._s(commit.author))]),
                      ])
                    }),
                    1
                  ),
                  _c("h6", { staticClass: "my-3" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$tr("themeDebugging")) + "\n    "
                    ),
                  ]),
                  _c(
                    "b-list-group",
                    { staticClass: "text-dark" },
                    _vm._l(_vm.testChannels, function (channelName, channelId) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: channelId,
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.goToTestPage(channelId)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(channelName) + "\n      "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }