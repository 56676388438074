var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.text !== ""
    ? _c(
        "span",
        {
          style: {
            display: "inline-block",
            maxWidth: "100%",
            overflow: "hidden",
          },
        },
        [
          _c(
            "span",
            _vm._b(
              {
                style: Object.assign(
                  {},
                  { display: "inline-block", maxWidth: "100%" },
                  _vm.truncate
                ),
                attrs: { title: _vm.text },
              },
              "span",
              _vm.$attrs,
              false
            ),
            [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }