var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-buttons" },
    [
      !_vm.showDownloadFeature
        ? _c("EkPlayButton", {
            staticClass: "play-button",
            attrs: { kind: _vm.kind, enabled: _vm.isEnabled },
            on: {
              click: function ($event) {
                return _vm.$emit("playButtonClicked")
              },
            },
          })
        : _vm._e(),
      _vm.showDownloadFeature
        ? _c(
            "b-button",
            {
              class: _vm.downloadButtonClasses,
              attrs: { pill: "", size: "sm", variant: _vm.kind + "-primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("downloadButtonClicked")
                },
              },
            },
            [
              _vm.downloadText
                ? _c("span", { staticClass: "download-button-text" }, [
                    _vm._v("\n      " + _vm._s(_vm.downloadText) + "\n    "),
                  ])
                : _vm._e(),
              _vm.isDownloadReady
                ? _c("DownloadIcon", {
                    attrs: { title: _vm.$tr("downloadLabel") },
                  })
                : _vm.isDownloading
                ? _c("DownloadingIcon", {
                    staticClass: "downloading",
                    attrs: { title: _vm.$tr("downloadingLabel") },
                  })
                : _vm.isDownloadCompleted
                ? _c("DownloadCompletedIcon", {
                    staticClass: "completed",
                    attrs: { title: _vm.$tr("downloadCompletedLabel") },
                  })
                : _vm.isDownloadFailed
                ? _c(
                    "span",
                    [
                      _c("DownloadWarningIcon", {
                        staticClass: "failed",
                        attrs: { title: _vm.$tr("downloadRestartLabel") },
                      }),
                      _c("DownloadRestartIcon", {
                        attrs: { title: _vm.$tr("downloadRestartLabel") },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }