import { render, staticRenderFns } from "./EkCardBody.vue?vue&type=template&id=a5cf31aa&scoped=true&"
import script from "./EkCardBody.vue?vue&type=script&lang=js&"
export * from "./EkCardBody.vue?vue&type=script&lang=js&"
import style0 from "./EkCardBody.vue?vue&type=style&index=0&id=a5cf31aa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/kolibri-tools/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5cf31aa",
  null
  
)

export default component.exports