var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "channels pb-5" },
    [
      _c("h5", { staticClass: "text-muted" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.$tr("channelsAlphabeticalTitle")) + "\n  "
        ),
      ]),
      _c("EkChannelCardGroup", {
        staticClass: "pb-4",
        attrs: { rows: _vm.chunk(_vm.orderedChannels), columns: _vm.columns },
        on: { "card-click": _vm.goToChannel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }