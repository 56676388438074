var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.isKolibriApiInjected,
        expression: "isKolibriApiInjected",
      },
    ],
    ref: "iframe",
    staticClass: "custom-presentation-iframe",
    attrs: { frameBorder: "0", src: _vm.rooturl },
    on: { load: _vm.onIframeLoad },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }