var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    { staticClass: "row", attrs: { name: "fade", mode: "in-out", tag: "div" } },
    _vm._l(_vm.nodes, function (node) {
      return _c(
        "b-col",
        {
          key: node.id,
          attrs: {
            cols: _vm.cardColumns.cols,
            md: _vm.cardColumns.md,
            lg: _vm.cardColumns.lg,
          },
        },
        [
          _c("EkCard", {
            attrs: { node: node, mediaQuality: _vm.mediaQuality },
            on: { nodeUpdated: _vm.onNodeUpdated },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }