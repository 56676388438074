var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-row" },
    [
      _c(
        "b-container",
        { staticClass: "py-4" },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "prepend",
                  fn: function () {
                    return [_c("b-input-group-text", [_c("MagnifyIcon")], 1)]
                  },
                  proxy: true,
                },
                {
                  key: "append",
                  fn: function () {
                    return [
                      _vm.value
                        ? _c(
                            "b-button",
                            {
                              staticClass: "text-primary",
                              attrs: { variant: "link" },
                              on: { click: _vm.clearSearchInput },
                            },
                            [_c("CloseIcon")],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("input", {
                ref: "searchInput",
                staticClass: "form-control",
                attrs: {
                  placeholder: _vm.$tr("searchPlaceholder"),
                  disabled: _vm.loading,
                },
                domProps: { value: _vm.value },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.inputUpdated($event.target.value)
                  },
                  input: function ($event) {
                    return _vm.updateValue($event.target.value)
                  },
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm.progress < 100
        ? _c("b-progress", {
            attrs: {
              value: _vm.progress,
              max: 100,
              height: "2px",
              variant: "primary",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }