var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      _vm.hasMultipleSlides
        ? _c(
            "b-button",
            {
              staticClass: "previous rounded-circle",
              class: { invisible: _vm.isFirstSlide },
              attrs: {
                variant: "outline-primary",
                "aria-controls": "carousel",
              },
              on: {
                click: function ($event) {
                  return _vm.previous()
                },
              },
            },
            [
              _c("ChevronLeftIcon", {
                attrs: { title: _vm.$tr("previousSlide") },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", {
        class: { white: _vm.hasWhiteBackground },
        attrs: { id: "backgroud-block-left" },
      }),
      _c("div", {
        class: { white: _vm.hasWhiteBackground },
        attrs: { id: "backgroud-block-right" },
      }),
      _vm.hasMultipleSlides
        ? _c(
            "b-button",
            {
              staticClass: "next rounded-circle",
              class: { invisible: _vm.hideNextButton },
              attrs: {
                variant: "outline-primary",
                disabled: _vm.loading,
                "aria-controls": "carousel",
              },
              on: {
                click: function ($event) {
                  return _vm.next()
                },
              },
            },
            [
              _c("ChevronRightIcon", {
                attrs: { title: _vm.$tr("nextSlide") },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-carousel",
        {
          ref: "carousel",
          attrs: { interval: 0 },
          on: { "sliding-end": _vm.onSlidingEnd },
          model: {
            value: _vm.slide,
            callback: function ($$v) {
              _vm.slide = $$v
            },
            expression: "slide",
          },
        },
        _vm._l(_vm.slides, function (slideNodes, index) {
          return _c("b-carousel-slide", {
            key: "slide-" + index,
            scopedSlots: _vm._u(
              [
                {
                  key: "img",
                  fn: function () {
                    return [
                      _c(
                        "b-card-group",
                        { staticClass: "card-deck my-2", attrs: { deck: "" } },
                        [
                          _vm._t("default", null, { slideNodes: slideNodes }),
                          _vm._l(_vm.emptyCardsNumber, function (n) {
                            return index === _vm.slides.length - 1
                              ? _c("b-card", {
                                  key: n,
                                  staticClass: "invisible",
                                })
                              : _vm._e()
                          }),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }