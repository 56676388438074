var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("EkGridPage", {
        attrs: {
          nodes: _vm.visibleNodes,
          cardColumns: _vm.cardColumns,
          mediaQuality: _vm.mediaQuality,
        },
        on: { nodeUpdated: _vm.onNodeUpdated },
      }),
      _c(
        "b-row",
        { attrs: { alignH: "center" } },
        [
          _vm.canShowMore
            ? _c(
                "b-button",
                {
                  staticClass: "mt-2",
                  attrs: {
                    disabled: _vm.loading,
                    pill: "",
                    variant: "outline-dark",
                  },
                  on: { click: _vm.showMore },
                },
                [
                  _vm.loading
                    ? _c("b-spinner", {
                        attrs: { label: _vm.$tr("spinnerLabel"), small: "" },
                      })
                    : _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$tr("showMoreButton")) +
                            "\n      "
                        ),
                      ]),
                ],
                1
              )
            : _vm.canShowLess
            ? _c(
                "b-button",
                {
                  staticClass: "mt-2",
                  attrs: { pill: "", variant: "outline-dark" },
                  on: { click: _vm.showLess },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$tr("showLessButton")))])]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }