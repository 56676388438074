var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-item",
      class: { "content-item--dark": _vm.isDark },
    },
    [
      _vm.sessionReady
        ? [
            !_vm.assessment
              ? _c(
                  "KContentRenderer",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "content-renderer",
                        class: {
                          "without-fullscreen-bar": _vm.withoutFullscreenBar,
                        },
                      },
                      "KContentRenderer",
                      _vm.contentProps,
                      false
                    ),
                    _vm.contentHandlers
                  )
                )
              : _c(
                  "AssessmentWrapper",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "content-renderer",
                        class: {
                          "without-fullscreen-bar": _vm.withoutFullscreenBar,
                        },
                      },
                      "AssessmentWrapper",
                      _vm.exerciseProps,
                      false
                    ),
                    _vm.contentHandlers
                  )
                ),
          ]
        : _c("div", { staticClass: "text-center" }, [_c("b-spinner")], 1),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }