var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        _vm.tag,
        { tag: "component" },
        [
          _c("EkClamp", {
            attrs: { maxLines: _vm.lines, text: _vm.node.title },
          }),
        ],
        1
      ),
      _vm.showDescription
        ? _c(
            "p",
            {
              staticClass:
                "align-self-center d-none d-sm-block mb-1 subtitle text-muted",
            },
            [
              _c("EkClamp", {
                attrs: { maxLines: _vm.descriptionLines, text: _vm.subtitle },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }