var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mb-5" },
    [
      _c(
        "b-row",
        {
          class: {
            "d-flex": true,
            "justify-content-between": _vm.showStoreButtons,
            "justify-content-around": !_vm.showStoreButtons,
          },
        },
        [
          _c(
            "b-col",
            { staticClass: "col-auto d-flex justify-content-start" },
            [
              _c(
                "b-container",
                { staticClass: "h-100", attrs: { fillHeight: "" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "d-flex h-100 justify-content-center" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "col-auto d-flex justify-content-start",
                        },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.about-modal",
                                  modifiers: { "about-modal": true },
                                },
                              ],
                              staticClass: "btn d-md-block d-none shadow-none",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tr("aboutLabel")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "col-auto d-flex justify-content-end" },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.about-modal",
                                  modifiers: { "about-modal": true },
                                },
                              ],
                              staticClass: "btn d-md-block d-none shadow-none",
                              on: {
                                click: function ($event) {
                                  return _vm.$root.$emit(
                                    "setAboutSection",
                                    "privacy-policy-link"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tr("privacyPolicyLabel")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showStoreButtons
            ? _c(
                "b-col",
                { staticClass: "col-auto d-flex justify-content-end" },
                [
                  _c(
                    "b-container",
                    { staticClass: "h-100", attrs: { fillHeight: "" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "d-flex h-100 justify-content-center" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "col-auto d-flex justify-content-start",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "google-play-button",
                                  attrs: {
                                    href: _vm.googleStoreUrl,
                                    target: "_blank",
                                  },
                                  on: { click: _vm.installFromPlayStore },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.googlePlayImage,
                                      alt: _vm.$tr("getOnGooglePlay"),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "col-auto d-flex justify-content-end",
                            },
                            [
                              _c("script", {
                                attrs: {
                                  type: "module",
                                  src: "https://get.microsoft.com/badge/ms-store-badge.bundled.js",
                                },
                              }),
                              _c("ms-store-badge", {
                                attrs: {
                                  productid: _vm.windowsApplicationId,
                                  cid: "ek-online",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }