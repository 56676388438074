var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex main-container min-vh-100 text-center" },
    [
      _c(
        "b-container",
        {
          staticClass:
            "d-flex flex-column flex-grow-1 no-container-padding welcome-container",
        },
        [
          _c(
            "b-container",
            {
              staticClass:
                "d-flex flex-column flex-grow-1 justify-content-center no-container-padding",
            },
            [
              _c("h1", { staticClass: "mb-3" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$tr("titleLabel")) + "\n      "
                ),
              ]),
              _c(
                "b-row",
                { staticClass: "justify-content-center mt-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", sm: "8" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            variant: "primary",
                            to: _vm.homePageLink,
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tr("retryLink")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }