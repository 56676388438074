var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      staticClass: "pb-1 pl-1 pt-1 text-nowrap",
      attrs: {
        pill: "",
        size: _vm.size,
        block: _vm.block,
        variant: "gray-primary",
        disabled: _vm.buttonDisabled,
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.$emit("click")
        },
      },
    },
    [
      _c(_vm.icon, { tag: "component", attrs: { size: _vm.iconSize } }),
      _c("span", { staticClass: "play-button-text" }, [
        _vm._v("\n    " + _vm._s(_vm.mediaInfo) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }