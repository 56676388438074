var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer" },
    [
      _c(
        "b-container",
        [
          _vm._t("default", function () {
            return [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "6" } }, [_vm._t("left")], 2),
                  _c("b-col", { attrs: { cols: "2" } }),
                  _c("b-col", [_vm._t("right")], 2),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }