var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("EkBackToTop"),
      _c("ContentModal"),
      _c("AboutModal", { attrs: { id: "about-modal" } }),
      _vm.showBuildInfo ? _c("DevTag") : _vm._e(),
      _c(
        "keep-alive",
        { attrs: { include: "SearchPage" } },
        [
          _vm.currentPage
            ? _c(
                _vm.currentPage,
                _vm._b(
                  {
                    tag: "component",
                    on: {
                      customPresentationLoadStarted:
                        _vm.onCustomPresentationLoadStarted,
                      customPresentationLoadCompleted:
                        _vm.onCustomPresentationLoadCompleted,
                    },
                  },
                  "component",
                  _vm.currentPageProperties,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
      _c("b-overlay", {
        attrs: { show: _vm.isCustomPresentationLoading, noWrap: "" },
        scopedSlots: _vm._u([
          {
            key: "overlay",
            fn: function () {
              return [_c("img", { attrs: { src: _vm.loadingImg } })]
            },
            proxy: true,
          },
        ]),
      }),
      _c("router-view"),
      _vm.enableSideNav
        ? _c("SideNav", {
            ref: "sideNav",
            staticClass: "side-nav",
            attrs: { navShown: _vm.showSideNav },
            on: {
              toggleSideNav: function ($event) {
                return _vm.toggleSideNav()
              },
              shouldFocusFirstEl: function ($event) {
                return _vm.findFirstEl()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }