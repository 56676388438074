var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WelcomeBase", {
    attrs: { title: _vm.$tr("packReadyTitle") },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "pack-background" }),
            _c(
              "b-button",
              {
                staticClass: "mb-4 mt-4",
                attrs: {
                  pill: "",
                  disabled: _vm.isDownloadRequired && _vm.isOffline,
                  variant: "primary",
                },
                on: { click: _vm.downloadContent },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$tr("downloadContentButton")) +
                    "\n    "
                ),
              ]
            ),
            _c(
              "p",
              [
                _c(
                  "b-link",
                  {
                    attrs: {
                      to: { name: _vm.PageNames.WELCOME_PACK_SELECTION },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tr("downloadContentBackButton")) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }