var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    _vm._l(_vm.words, function (w) {
      return _c(
        "b-button",
        {
          key: w,
          staticClass: "mx-1 pr-1 py-1",
          attrs: { pill: "", variant: "outline-primary" },
          on: {
            click: function ($event) {
              return _vm.$emit("click", w)
            },
          },
        },
        [
          _vm._v("\n    " + _vm._s(w) + "\n    "),
          _vm._t("default", function () {
            return [
              _c("CloseIcon", {
                staticClass: "ml-2",
                attrs: { size: _vm.iconSize },
              }),
            ]
          }),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }