var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      staticClass: "my-2 rounded-lg",
      class: {
        "shadow-sm": !_vm.isHovered,
        shadow: _vm.isHovered,
      },
      attrs: { noBody: "" },
    },
    [
      _c(
        "EkContentLink",
        {
          attrs: { url: _vm.url },
          on: {
            isHovered: function (hovered) {
              return (_vm.isHovered = hovered)
            },
          },
        },
        [
          _c("b-card-body", { staticClass: "bg-secondary" }, [
            _c(
              "div",
              {
                staticClass: "card-img",
                class: {
                  "low-quality": _vm.isLowQuality,
                  "is-thumbnail-wide": _vm.isThumbnailWide,
                },
                style: _vm.cardStyle,
              },
              [
                _c("span", { staticClass: "sr-only" }, [
                  _vm._v(_vm._s(_vm.node.title)),
                ]),
                _c("span", { staticClass: "debug-node-id sr-only" }, [
                  _vm._v(_vm._s(_vm.node.id)),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "body-wrapper" },
              [
                _c(
                  "div",
                  { staticClass: "card-content" },
                  [
                    _c(
                      "b-card-title",
                      { staticClass: "mb-1", attrs: { titleTag: "h5" } },
                      [
                        _c("EkClamp", {
                          attrs: {
                            maxLines: _vm.titleMaxLines,
                            text: _vm.node.title,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-card-text",
                      [
                        _c("EkClamp", {
                          attrs: {
                            maxLines: _vm.descriptionMaxLines,
                            text: _vm.node.description,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("b-card-text", { staticClass: "my-1" }, [
                  _vm.showChannelIcon
                    ? _c(
                        "div",
                        { staticClass: "align-items-center d-flex" },
                        [
                          _c("EkChannelLogo", {
                            staticClass: "mr-2",
                            attrs: { channel: _vm.node.channel, size: "sm" },
                          }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "channel-title subtitle text-truncate",
                            },
                            [_vm._v(_vm._s(_vm.node.channel.title))]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "b-badge",
                            { attrs: { pill: "", variant: "secondary" } },
                            [
                              _c("BundleIcon", { attrs: { size: 20 } }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tr("exploreBadge")) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }