var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.buttonsVisible, function (button) {
        return _c(
          "b-button",
          {
            key: button,
            staticClass: "mr-2",
            attrs: { pill: "", size: "sm", variant: "primary" },
            on: {
              click: function ($event) {
                return _vm.$emit("click", button)
              },
            },
          },
          [_vm._v("\n    " + _vm._s(button) + "\n  ")]
        )
      }),
      _vm.buttonsHidden.length
        ? _c(
            "b-dropdown",
            {
              ref: "dropdown",
              staticClass: "mr-2",
              attrs: {
                size: "sm",
                variant: "primary",
                text: "···",
                noCaret: "",
                right: "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex filter-dropdown flex-column" },
                [
                  _c(
                    "b-dropdown-header",
                    [
                      _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
                      _c("b-button-close", { on: { click: _vm.close } }),
                    ],
                    1
                  ),
                  _c("b-dropdown-divider", { staticClass: "my-1" }),
                  _c(
                    "div",
                    { staticClass: "filter-content flex-fill mx-1" },
                    _vm._l(_vm.buttonsHidden, function (button) {
                      return _c(
                        "b-button",
                        {
                          key: button,
                          staticClass: "mt-2 mx-1",
                          attrs: { pill: "", size: "sm", variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("click", button)
                            },
                          },
                        },
                        [_vm._v("\n          " + _vm._s(button) + "\n        ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }