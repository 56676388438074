var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex main-container min-vh-100 text-center" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column flex-grow-1 w-100" },
        [
          _c("div", { staticClass: "flex-grow-1 welcome-background-top" }),
          _c("div", { staticClass: "content p-3 pb-5 position-relative" }, [
            _c("h1", [_vm._v(_vm._s(_vm.$tr("welcomeTitle")))]),
            _c(
              "div",
              { staticClass: "mb-4 mt-4" },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      pill: "",
                      variant: "primary",
                      to: { name: _vm.PageNames.WELCOME_PACK_SELECTION },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tr("getStartedButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "p",
              [
                _c(
                  "b-link",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal.privacy-policy-modal",
                        modifiers: { "privacy-policy-modal": true },
                      },
                    ],
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tr("privacyPolicy")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "flex-grow-1 welcome-background-bottom" }),
          _c(
            "b-modal",
            {
              attrs: {
                id: "privacy-policy-modal",
                headerCloseVariant: "light",
                size: "xl",
                centered: "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "bg-white modal-wrapper text-dark" },
                [
                  _c("h3", { staticClass: "pt-3 text-primary" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tr("privacyPolicy")) +
                        "\n        "
                    ),
                  ]),
                  _c("EkPrivacyPolicyText", { staticClass: "pb-3" }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }