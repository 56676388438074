var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [_c("em", [_vm._v("Last Revised: October 5th, 2022")])]),
      _c("p", [
        _vm._v(
          "\n    Welcome, and thank you for your interest in the Endless Key,\n    whether accessed online, as an application on your personal\n    device, or via USB disk (the “"
        ),
        _c("b", [_vm._v("Service")]),
        _vm._v(
          "”). Endless Key is\n    developed and published by Endless OS Foundation, a 501(c)(4) US\n    non-profit organization, with the mission of helping school-aged\n    students access great educational and learning resources without\n    an internet connection.\n  "
        ),
      ]),
      _c("p", [
        _vm._v("\n    This Endless Key Privacy Policy (this “"),
        _c("b", [_vm._v("Policy")]),
        _vm._v(
          "”) describes\n    the information that we gather from your usage of the Service,\n    how we use and disclose such information, and the steps we take\n    to protect such information. Please read this Policy carefully\n    before using our Service. By using the Service, you consent to\n    our use of your information in accordance with the privacy\n    practices described in this Policy.\n  "
        ),
      ]),
      _c("h5", [_vm._v("General Statement")]),
      _c("p", [
        _vm._v(
          "\n    We collect information from use of the Service solely for\n    carefully considered and specific purposes, which are for the\n    mutual benefit of ourselves and the Endless OS Foundation\n    mission, as well as our users. We make every effort to avoid\n    collecting information closely associated with your personal\n    identity. With respect to information tied to particular\n    devices, we pseudonymize such information and use it solely to\n    support our internal operations and improvement of the\n    Services. We will never sell your data nor use it to market\n    products or services to you.\n  "
        ),
      ]),
      _c("h5", [_vm._v("Information We Collect")]),
      _c("p", [
        _vm._v(
          "\n    We collect the following information automatically when you use\n    the Service:\n  "
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "\n      Summary statistics regarding your access to and use of the\n      Services, such as which educational content resources have\n      been made available to you via the Service, and their\n      popularity.\n    "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n      If you run or install the Service on your device as an\n      application, for example through your device’s App Store or by\n      running Endless Key from a USB disk, we will automatically\n      collect the following additional information:\n      "
          ),
          _c("ul", [
            _c("li", [
              _vm._v(
                "\n          Pseudo-anonymized identifiers corresponding to the Endless\n          Key application instance and the device where it is\n          running.\n        "
              ),
            ]),
            _c("li", [_vm._v("\n          Your IP address\n        ")]),
            _c("li", [
              _vm._v(
                "\n          Version information corresponding to running Endless Key\n          application and the software technologies it is built upon\n        "
              ),
            ]),
            _c("li", [
              _vm._v(
                "\n          High level information about the device type and operating\n          system where the Endless Key application is running\n        "
              ),
            ]),
            _c("li", [
              _vm._v(
                "\n          Your system language, timezone, system time, and the time\n          since the Service was started.\n        "
              ),
            ]),
          ]),
        ]),
      ]),
      _c("h5", [_vm._v("How We Use Information")]),
      _c("p", [
        _vm._v(
          "\n    We may process the information collected from your use of the\n    Services for carefully considered and specific purposes which\n    are in our interests and enable us to enhance the services we\n    provide, but which we believe also benefit our users:\n  "
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "\n      We use the information collected via the Service for Endless\n      OS Foundation’s internal operations. This includes\n      understanding and analyzing the usage trends and preferences\n      of our users with the aim of increasing the educational value\n      of the software and content, and monitoring our performance in\n      order to issue enhancements to the user experience and\n      functionality.\n    "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n      We may use the data to derive certain highly aggregated\n      statistics and make them available to third parties or to the\n      general public, for various purposes, including to demonstrate\n      the impact of our software, as well as to convey our users’\n      general interests, habits, and usage patterns of the software.\n    "
          ),
        ]),
      ]),
      _c("p", [
        _vm._v(
          "\n    Information collected from your use of the Service will never be\n    sold to third parties, nor will it be used to advertise or\n    market external products or services to users.\n  "
        ),
      ]),
      _c("h5", [_vm._v("When We Disclose Information")]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "\n      We may provide access to or share your information with third\n      parties performing services supporting the functionality of\n      the Service. In particular, the Service utilizes the Kolibri\n      platform developed by Learning Equality, a 501(c)(3) nonprofit\n      organization.  Data will only be shared with or used by\n      Learning Equality or any other such third parties for the\n      purposes permitted above.\n    "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n      We may disclose your information if required to do so by law\n      or in the good-faith belief that such action is necessary to\n      comply with state and federal laws (such as U.S. copyright\n      law), in response to a court order, judicial or other\n      government subpoena or warrant, or to otherwise cooperate with\n      law enforcement or other governmental agencies.\n    "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n      We also reserve the right to disclose your information that we\n      believe, in good faith, is appropriate or necessary to (i)\n      take precautions against liability, (ii) protect ourselves or\n      others from fraudulent, abusive, or unlawful uses or activity,\n      (iii) investigate and defend ourselves against any third-party\n      claims or allegations, (iv) protect the security or integrity\n      of the Service and any facilities or equipment used to make\n      the Service available, or (v) protect our property or other\n      legal rights (including, but not limited to, enforcement of\n      our agreements), or the rights, property, or safety of others.\n    "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n      Information collected via the Service may be disclosed and\n      otherwise transferred to an acquirer, or successor or assignee\n      as part of any merger, acquisition, debt financing, sale of\n      assets, or similar transaction, as well as in the event of an\n      insolvency, bankruptcy, or receivership in which information\n      is transferred to one or more third parties as one of our\n      business assets.\n    "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n      We also may disclose your information in any other manner to\n      which consent is provided directly from you, or where\n      required, the responsible parent.\n    "
          ),
        ]),
      ]),
      _c("h5", [_vm._v("Third-Party Websites")]),
      _c("p", [
        _vm._v(
          "\n    The Service may contain features or links to websites and\n    services provided by third parties. Any information you provide\n    on third-party sites or services is provided directly to the\n    operators of such services and is subject to those operators’\n    policies, if any, governing privacy and security, even if\n    accessed through the Service. We are not responsible for the\n    content or privacy and security practices and policies of\n    third-party sites or services to which links or access are\n    provided through the Website. We encourage you to learn about\n    third parties’ privacy and security policies before providing\n    them with information.\n  "
        ),
      ]),
      _c("h5", [_vm._v("Data Security")]),
      _c("p", [
        _vm._v(
          "\n    We use certain physical, managerial, and technical safeguards\n    that are designed to improve the integrity and security of your\n    personally identifiable information. We cannot, however, ensure\n    or warrant the security of any information you transmit to us,\n    and you do so at your own risk. We also cannot guarantee that\n    such information may not be accessed, disclosed, altered, or\n    destroyed by breach of any of our physical, technical, or\n    managerial safeguards.\n  "
        ),
      ]),
      _c("h5", [_vm._v("Legal Bases for Use")]),
      _c("p", [
        _vm._v(
          "\n    The laws of some jurisdictions, including the European Economic\n    Area and the United Kingdom, require that companies only process\n    your “Personal Data” (as that term is defined in the applicable\n    law, like the EU General Data Protection Regulation) if they\n    have a “legal basis” (or justifiable need) for processing such\n    Personal Data. To the extent those laws apply, our legal basis\n    for processing Personal Data are the following legitimate\n    interests:\n  "
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "\n      Enabling us to conduct analytics of the Service in order to\n      improve the Service, in particular improving the relevance of\n      content selection for future use and users (e.g., by\n      identifying what content is popular and unpopular);\n    "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n      Producing high-level data aggregations and statistics that\n      demonstrate the success of the Service and otherwise can be\n      utilized in furtherance of our nonprofit mission, including by\n      sharing such aggregate and statistical data with content\n      partners and in fundraising activities.\n    "
          ),
        ]),
      ]),
      _c("h5", [_vm._v("International Visitors")]),
      _c("p", [
        _vm._v(
          "\n    Data collected via the Service is transmitted to our processing\n    systems in the United States. If you choose to use the Service\n    from other regions of the world with laws governing data\n    collection and use that may differ from U.S. law, then please\n    note that the data collected by the Service is transferred\n    outside of those regions to the United States for storage and\n    processing. Also, we may transfer your data from the U.S. to\n    other countries or regions in connection with storage and\n    processing of data, and fulfilling your requests.\n  "
        ),
      ]),
      _c("h5", [_vm._v("Modifications")]),
      _c("p", [
        _vm._v(
          "\n    This Policy may be updated or modified at any time to reflect\n    changes in the law, our data collection and use practices, the\n    features of our Services or advances in technology.  The current\n    Policy will be made accessible through use of the Services with\n    an updated “Last Revised” date. In the event of any material\n    changes to this Policy, we will endeavor to notify our users to\n    the extent possible, including by posting a notice relating\n    thereto on the Service. Schools and school districts that have\n    formally notified us of their use of the Service will also\n    receive email notification of such changes.\n  "
        ),
      ]),
      _c("h5", [_vm._v("Our Contact Information")]),
      _c("p", [
        _vm._v(
          "\n    Please contact us with any questions or comments about this\n    Policy, your personal information, our use and disclosure\n    practices, or your consent choices by email at\n    legal@endlessos.org\n  "
        ),
      ]),
      _c("p", [
        _vm._v("\n    Endless OS Foundation LLC"),
        _c("br"),
        _vm._v("\n    24A Trolley Square # 2319"),
        _c("br"),
        _vm._v("\n    Wilmington, DE 19806"),
        _c("br"),
        _vm._v("\n    USA\n  "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }