var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.cardVariant, {
    tag: "component",
    attrs: {
      node: _vm.node,
      isBundle: _vm.isBundle,
      url: _vm.getNodeUrl(_vm.node),
      mediaQuality: _vm.mediaQuality,
    },
    on: { nodeUpdated: _vm.onNodeUpdated },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }