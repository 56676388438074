var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mb-4" },
    [
      _c(
        "b-card",
        { attrs: { noBody: "", imgLeft: "" } },
        [
          _c("b-skeleton-img", { attrs: { cardImg: "left" } }),
          _c(
            "b-card-body",
            { staticClass: "d-flex flex-column" },
            [
              _c("b-skeleton", { attrs: { width: "85%" } }),
              _c("b-skeleton", { attrs: { width: "55%" } }),
              _c(
                "div",
                {
                  staticClass:
                    "align-items-center d-flex justify-content-between mt-auto",
                },
                [
                  _c("b-skeleton", { attrs: { width: "20%" } }),
                  _c("b-skeleton", { attrs: { type: "button" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }