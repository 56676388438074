var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EkSlidableGrid", {
    staticClass: "pb-4",
    attrs: {
      nodes: _vm.nodes,
      hasMoreNodes: _vm.hasMoreNodes,
      itemsPerSlide: _vm.itemsPerSlide,
    },
    on: {
      loadMoreNodes: function ($event) {
        return _vm.$emit("loadMoreNodes")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (slotProps) {
          return _vm._l(slotProps.slideNodes, function (node) {
            return _c("EkCard", {
              key: node.id,
              attrs: { node: node, mediaQuality: _vm.mediaQuality },
              on: { nodeUpdated: _vm.onNodeUpdated },
            })
          })
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }