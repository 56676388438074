var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      staticClass: "mb-5 mt-3 section-container",
      class: { "no-container-padding": _vm.variant === "slidable" },
    },
    [
      _vm._t("default"),
      _c(_vm.displayVariant, {
        tag: "component",
        attrs: {
          nodes: _vm.nodes,
          hasMoreNodes: _vm.hasMoreNodes,
          itemsPerPage: _vm.itemsPerPage,
          itemsPerSlide: _vm.itemsPerSlide,
          mediaQuality: _vm.mediaQuality,
          cardColumns: _vm.cardColumns,
        },
        on: {
          loadMoreNodes: function ($event) {
            return _vm.$emit("loadMoreNodes")
          },
          nodeUpdated: _vm.onNodeUpdated,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }