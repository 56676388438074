var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mb-5" },
    [
      _c(
        "b-carousel",
        {
          staticClass: "bg-secondary rounded-lg with-shadow",
          attrs: { fade: "", indicators: "", interval: _vm.interval },
          model: {
            value: _vm.slide,
            callback: function ($$v) {
              _vm.slide = $$v
            },
            expression: "slide",
          },
        },
        _vm._l(_vm.nodes, function (node) {
          return _c("b-carousel-slide", {
            key: "item-" + node.id,
            scopedSlots: _vm._u(
              [
                {
                  key: "img",
                  fn: function () {
                    return [
                      _c(
                        "EkContentLink",
                        { attrs: { url: _vm.getNodeUrl(node) } },
                        [
                          _c("EkCarouselCard", {
                            attrs: {
                              showChannelIcon: _vm.showChannelIcon,
                              node: node,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }