var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      staticClass: "my-2 rounded-lg",
      class: {
        "shadow-sm": !_vm.isHovered && _vm.isEnabled,
        shadow: _vm.isHovered && _vm.isEnabled,
        disabled: _vm.isDisabled,
      },
      attrs: { noBody: "", disabled: _vm.isDisabled },
    },
    [
      _c(
        "EkContentLink",
        {
          attrs: { enabled: _vm.isEnabled, url: _vm.url },
          on: {
            isHovered: function (hovered) {
              return (_vm.isHovered = hovered)
            },
          },
        },
        [
          _c("b-card-body", [
            _c(
              "div",
              {
                staticClass: "card-img",
                class: {
                  "low-quality": _vm.isLowQuality,
                  "is-thumbnail-wide": _vm.isThumbnailWide,
                },
                style: _vm.cardStyle,
              },
              [
                _c("span", { staticClass: "sr-only" }, [
                  _vm._v(_vm._s(_vm.node.title)),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "body-wrapper" },
              [
                _c("EkCardBody", {
                  attrs: { node: _vm.node, subtitle: _vm.subtitle },
                }),
                _c("EkCardButtons", {
                  attrs: {
                    node: _vm.node,
                    kind: _vm.kind,
                    isEnabled: _vm.isEnabled,
                    downloadState: _vm.downloadState,
                  },
                  on: {
                    downloadButtonClicked: _vm.onDownloadButtonClick,
                    playButtonClicked: _vm.onPlayButtonClick,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }