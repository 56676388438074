import { render, staticRenderFns } from "./EkChannelLogo.vue?vue&type=template&id=2bf1c6d8&scoped=true&"
import script from "./EkChannelLogo.vue?vue&type=script&lang=js&"
export * from "./EkChannelLogo.vue?vue&type=script&lang=js&"
import style0 from "./EkChannelLogo.vue?vue&type=style&index=0&id=2bf1c6d8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/kolibri-tools/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bf1c6d8",
  null
  
)

export default component.exports