var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column min-vh-100 search-page" },
    [
      _c("DiscoveryNavBar"),
      _c(
        "div",
        { staticClass: "flex-fill" },
        [
          _c("EkSearchBar", {
            staticClass: "search-row",
            attrs: { progress: _vm.progress, loading: _vm.isLoading },
            on: { "clear-input": _vm.clearInput },
            model: {
              value: _vm.query,
              callback: function ($$v) {
                _vm.query = $$v
              },
              expression: "query",
            },
          }),
          _vm.isEmpty
            ? _c(
                "div",
                { staticClass: "mt-5" },
                [_c("AlphabeticalChannelsList")],
                1
              )
            : [
                _c(
                  "b-container",
                  [
                    _c(
                      "b-row",
                      { attrs: { alignH: "between" } },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: {
                                  name: "check-hide-unavailable",
                                  switch: "",
                                },
                                model: {
                                  value: _vm.hideUnavailable,
                                  callback: function ($$v) {
                                    _vm.hideUnavailable = $$v
                                  },
                                  expression: "hideUnavailable",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$tr("hideUnavailableLabel")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "text-right" },
                          [
                            _c("EkKeywords", {
                              attrs: { words: _vm.keywords },
                              on: { click: _vm.removeKeyword },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.resultCards
                  ? _vm._l(_vm.resultCards, function (ref) {
                      var kind = ref[0]
                      var nodes = ref[1]
                      return _c(
                        "div",
                        { key: kind },
                        [
                          _c(
                            "EkCardGrid",
                            {
                              attrs: {
                                id: "root",
                                variant: "collapsible",
                                itemsPerPage: 4,
                                nodes: nodes,
                                mediaQuality: _vm.mediaQuality,
                                cardColumns: _vm.cardColumns,
                              },
                              on: { nodeUpdated: _vm.onNodeUpdated },
                            },
                            [
                              _c("div", [
                                _c("h4", { staticClass: "text-muted" }, [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-normal" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tr("countedResults", {
                                              count: nodes.length,
                                              term: _vm.cleanedQuery,
                                              action: _vm.groupVerb(kind),
                                            })
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c("hr"),
                        ],
                        1
                      )
                    })
                  : _vm._e(),
                _vm.isLoading ? [_c("EkCardGridPlaceholder")] : _vm._e(),
                _c(
                  "b-container",
                  { staticClass: "pb-5" },
                  [
                    !_vm.isLoading
                      ? [
                          _vm.isNoResults
                            ? _c(
                                "div",
                                { staticClass: "empty mb-4 mt-5 w-50" },
                                [
                                  _c("h1", [
                                    _vm._v(_vm._s(_vm.$tr("noResultsHeading"))),
                                  ]),
                                  _c("h5", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$tr("noResultsBody")) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm.resultChannels.length
                  ? _c(
                      "b-container",
                      { staticClass: "pb-5 pt-3" },
                      [
                        _c("h4", { staticClass: "text-muted" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$tr("countedChannels", {
                                  count: _vm.resultChannels.length,
                                  term: _vm.cleanedQuery,
                                })
                              ) +
                              "\n        "
                          ),
                        ]),
                        _c("EkChannelCardGroup", {
                          attrs: {
                            variant: "smallCard",
                            rows: _vm.resultChannelsColumns,
                            columns: _vm.columns,
                          },
                          on: { "card-click": _vm.goToChannel },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.recommended && _vm.isNoResults
                  ? _c(
                      "div",
                      { staticClass: "flex-shrink-0 pt-5 recommended" },
                      [
                        _vm.recommended
                          ? _c(
                              "b-container",
                              { staticClass: "pb-5 pt-3" },
                              [
                                _c("h3", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$tr("noResultsExplore")) +
                                      "\n          "
                                  ),
                                ]),
                                _c(
                                  "h5",
                                  [
                                    _c(
                                      "b-link",
                                      { on: { click: _vm.clearInput } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$tr("noResultsSeeAllChannels")
                                            ) +
                                            " "
                                        ),
                                        _c("b-icon-arrow-right"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("EkChannelCardGroup", {
                                  attrs: {
                                    rows: _vm.recommended,
                                    columns: _vm.columns,
                                  },
                                  on: { "card-click": _vm.goToChannel },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
          _c("AboutFooter"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }