var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channels-page d-flex flex-column min-vh-100" },
    [
      _c("DiscoveryNavBar"),
      _c("b-container", { staticClass: "mb-2 mt-4" }, [
        _c("h5", { staticClass: "mt-2 text-muted" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$tr("contentSuggestionsHeading")) + "\n    "
          ),
        ]),
      ]),
      _vm.loading
        ? [_c("EkCarouselPlaceholder")]
        : [
            _c(
              "b-container",
              { staticClass: "mb-2 mt-2" },
              [
                _c("EkButtonsBar", {
                  staticClass: "mr-3 mt-1",
                  attrs: {
                    title: _vm.$tr("moreTopics"),
                    buttons: Array.from(_vm.getSearchTerms().keys()),
                  },
                  on: { click: _vm.goToTerm },
                }),
              ],
              1
            ),
            _c("EkCarousel", {
              staticClass: "pt-3",
              attrs: { nodes: _vm.carouselNodes, showChannelIcon: true },
            }),
          ],
      _c(
        "div",
        { staticClass: "flex-fill main" },
        [_vm.loading ? [_c("EkCardGridPlaceholder")] : [_c("EkIguanaList")]],
        2
      ),
      _c("AboutFooter", { staticClass: "pt-3" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }