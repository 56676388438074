var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      attrs: { overlay: "", noBody: "" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("h5", [_vm._v(_vm._s(_vm.title))]),
              _c("p", [_vm._v("\n      " + _vm._s(_vm.subTitle) + "\n    ")]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("b-link", {
        staticClass: "stretched-link",
        attrs: { href: "#" },
        on: { click: _vm.onClick },
      }),
      _c("div", { staticClass: "pack-card-background", class: _vm.packId }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }