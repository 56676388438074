var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "content-modal",
        modalClass: _vm.contentModalClass,
        size: _vm.size,
        centered: "",
        busy: "",
        title: _vm.content.title,
        headerCloseVariant: "light",
      },
      model: {
        value: _vm.showingModal,
        callback: function ($$v) {
          _vm.showingModal = $$v
        },
        expression: "showingModal",
      },
    },
    [_c("ContentItem", { attrs: { isDark: "", contentNode: _vm.content } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }