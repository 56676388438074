var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.rows, function (row, index) {
      return _c(
        "b-card-group",
        { key: "row-" + index, staticClass: "card-row", attrs: { deck: "" } },
        [
          _vm._l(row, function (channel) {
            return _c("EkChannelCard", {
              key: channel.id,
              attrs: { channel: channel, variant: _vm.variant },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("card-click", channel.id)
                },
              },
            })
          }),
          _vm._l(_vm.columns - row.length, function (n) {
            return index === _vm.rows.length - 1
              ? _c("b-card", { key: n, staticClass: "invisible" })
              : _vm._e()
          }),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }