var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      directives: [
        {
          name: "b-hover",
          rawName: "v-b-hover",
          value: _vm.handleHover,
          expression: "handleHover",
        },
      ],
      staticClass: "text-decoration-none text-reset",
      attrs: { role: "button", disabled: _vm.contentLinkDisabled },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }