var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-4", attrs: { id: "categorized" } },
    _vm._l(_vm.categories, function (ref) {
      var name = ref.name
      var channels = ref.channels
      var contentPicks = ref.contentPicks
      return _c(
        "div",
        { key: name, staticClass: "pb-4 pt-4" },
        [
          _c("b-container", { staticClass: "pb-4" }, [
            _c("h5", { staticClass: "text-muted" }, [
              _vm._v("\n        " + _vm._s(name) + "\n      "),
            ]),
          ]),
          _c(
            "b-container",
            { staticClass: "no-container-padding" },
            [
              _c("EkSlidableGrid", {
                attrs: {
                  nodes: _vm.getSlidableGridNodes(channels, contentPicks),
                  hasWhiteBackground: true,
                  itemsPerSlide: { lg: 3, md: 2, sm: 1 },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (slotProps) {
                        return [
                          _vm._l(slotProps.slideNodes, function (node) {
                            return [
                              node !== undefined && node.kind === "channel"
                                ? _c("EkChannelCard", {
                                    key: node.id,
                                    attrs: { channel: node },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.goToChannel(node.id)
                                      },
                                    },
                                  })
                                : _c("EkCard", {
                                    key: node.id,
                                    attrs: { node: node },
                                  }),
                            ]
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }