var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channels-page d-flex flex-column min-vh-100" },
    [
      _c("DiscoveryNavBar"),
      _vm.loading || _vm.loadingNodes
        ? [_c("EkCardGridPlaceholder")]
        : [
            _c(
              "b-container",
              { staticClass: "mb-2 mt-4 no-container-padding" },
              [
                _vm.hasNodesForSection("featured-channel")
                  ? [
                      _c("b-container", [
                        _c("h5", { staticClass: "mt-2 text-muted" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tr("channelLabel")) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _c(
                        "b-container",
                        {
                          staticClass:
                            "mb-5 mt-3 no-container-padding section-container",
                        },
                        [
                          _c("EkSlidableGrid", {
                            attrs: {
                              nodes: _vm.sectionNodes["featured-channel"],
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (slotProps) {
                                    return _vm._l(
                                      slotProps.slideNodes,
                                      function (node) {
                                        return _c("EkChannelCard", {
                                          key: node.id,
                                          attrs: { channel: node },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.goToChannel(node.id)
                                            },
                                          },
                                        })
                                      }
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              3323344214
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.hasNodesForSection("highlight")
                  ? [
                      _c("b-container", [
                        _c("h5", { staticClass: "mt-2 text-muted" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tr("highlightLabel")) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _c("EkCardGrid", {
                        attrs: { nodes: _vm.sectionNodes["highlight"] },
                      }),
                    ]
                  : _vm._e(),
                _vm.hasNodesForSection("skill")
                  ? [
                      _c("b-container", [
                        _c("h5", { staticClass: "mt-2 text-muted" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tr("skillLabel")) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _c("EkCardGrid", {
                        attrs: { nodes: _vm.sectionNodes["skill"] },
                      }),
                    ]
                  : _vm._e(),
                _vm.hasNodesForSection("career")
                  ? [
                      _c("b-container", [
                        _c("h5", { staticClass: "mt-2 text-muted" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tr("careerLabel")) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _c("EkCardGrid", {
                        attrs: { nodes: _vm.sectionNodes["career"] },
                      }),
                    ]
                  : _vm._e(),
                _vm.hasNodesForSection("curious")
                  ? [
                      _c("b-container", [
                        _c("h5", { staticClass: "mt-2 text-muted" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tr("curiousLabel")) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _c("EkCardGrid", {
                        attrs: { nodes: _vm.sectionNodes["curious"] },
                      }),
                    ]
                  : _vm._e(),
                _c("AboutFooter"),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }