var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "navbar px-0",
      class: { shadow: _vm.castShadow },
      attrs: { fixed: "top" },
    },
    [
      _c(
        "b-container",
        { staticClass: "justify-content-start mx-3", attrs: { fluid: "" } },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }