var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WelcomeBase", {
    attrs: { title: _vm.$tr("packSelectionTitle") },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-container",
              { staticClass: "no-container-padding text-left" },
              [
                _c("EkSlidableGrid", {
                  staticClass: "mb-3",
                  attrs: {
                    nodes: _vm.PackMetadata,
                    hasWhiteBackground: true,
                    itemsPerSlide: { lg: 3, md: 2, sm: 1 },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (slotProps) {
                        return [
                          _vm._l(slotProps.slideNodes, function (pack) {
                            return [
                              _c("EkPackCard", {
                                key: pack.id,
                                attrs: {
                                  packId: pack.id,
                                  title: _vm.packMetadataTitle(pack),
                                  subTitle: _vm.packMetadataSubtitle(pack),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.choosePack(pack.id)
                                  },
                                },
                              }),
                            ]
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }