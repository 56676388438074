var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EkNavBar",
    { staticClass: "discovery-navbar" },
    [
      _c("img", {
        staticClass: "logo ml-3 mr-5",
        attrs: { src: _vm.logo },
        on: { click: _vm.onLogoClick },
      }),
      _c(
        "b-button-group",
        { staticClass: "mx-auto" },
        [
          _vm.showDiscoveryTab
            ? _c(
                "b-nav-text",
                {
                  staticClass: "btn discovery-tab py-3 rounded-0 text-primary",
                  class: { active: _vm.currentIsChannels() },
                  on: { click: _vm.goToChannels },
                },
                [
                  _c("ViewDashboardOutlineIcon"),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.exploreString("discoveryLabel")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-nav-text",
            {
              staticClass: "btn discovery-tab py-3 rounded-0 text-primary",
              class: { active: _vm.currentIsSearch() },
              on: { click: _vm.goToSearch },
            },
            [
              _c("MagnifyIcon"),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.exploreString("libraryLabel")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-navbar-nav",
        [
          _vm.showStoreButtons
            ? _c(
                "b-button",
                {
                  staticClass: "align-self-center",
                  attrs: { variant: "endless-orange", size: "sm", pill: "" },
                  on: { click: _vm.toBottom },
                },
                [
                  _c("span", { staticClass: "d-none d-sm-inline" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tr("downloadLabel")) +
                        "\n      "
                    ),
                  ]),
                  _c("span", { staticClass: "d-inline d-sm-none" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tr("downloadLabelShort")) +
                        "\n      "
                    ),
                  ]),
                  _c("ArrowDownIcon"),
                ],
                1
              )
            : _vm._e(),
          _vm.showFeedbackButton
            ? _c(
                "b-nav-item",
                {
                  staticClass: "d-block pr-0",
                  attrs: {
                    href: _vm.feedbackUrl,
                    target: "_blank",
                    disabled: _vm.isOffline,
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "d-inline" },
                    [_c("MessageReplyTextOutlineIcon")],
                    1
                  ),
                  _c("span", { staticClass: "d-none d-sm-inline" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tr("feedbackLabel")) +
                        "\n      "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }