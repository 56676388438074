var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { class: ((_obj = {}), (_obj[_vm.variant] = true), _obj) },
    [
      _vm.hasBigThumbnail
        ? _c("div", {
            staticClass: "bigThumbnail",
            style: _vm.bigThumbnailStyles,
          })
        : _vm._e(),
      _c(
        "b-card-header",
        {
          class: {
            withBigThumbnail: _vm.hasBigThumbnail,
            "pb-3": !_vm.isSmall,
          },
        },
        [
          _c("EkChannelLogo", {
            staticClass: "mr-3 rounded-logo",
            attrs: { channel: _vm.channel, size: "md" },
          }),
          _c("h6", [_vm._v("\n      " + _vm._s(_vm.channel.title) + "\n    ")]),
        ],
        1
      ),
      !_vm.isSmall
        ? _c(
            "b-card-text",
            { staticClass: "pt-2 text-muted" },
            [
              _c("EkClamp", {
                attrs: {
                  maxLines: _vm.maxDescriptionLines,
                  text: _vm.description,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }