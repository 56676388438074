var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-content" }, [
    _c("span", { staticClass: "debug-node-id sr-only" }, [
      _vm._v(_vm._s(_vm.node.id)),
    ]),
    _vm.showChannelIcon
      ? _c("div", [
          _c(
            "div",
            { staticClass: "d-flex mb-3" },
            [
              _c("EkChannelLogo", {
                staticClass: "mr-2",
                attrs: { channel: _vm.node.channel, size: "sm" },
              }),
              _c(
                "span",
                { staticClass: "channel-title text-muted text-truncate" },
                [_vm._v(_vm._s(_vm.node.channel.title))]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", [
      _c(
        "h5",
        {
          staticClass: "mb-1 title",
          class: { "text-muted": !_vm.node.available },
        },
        [
          _c("EkClamp", {
            attrs: { maxLines: _vm.titleLines, text: _vm.node.title },
          }),
        ],
        1
      ),
    ]),
    !_vm.showChannelIcon
      ? _c("div", [
          _c(
            "p",
            { staticClass: "mb-1 subtitle text-muted" },
            [_c("EkClamp", { attrs: { maxLines: 3, text: _vm.subtitle } })],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }